import { hexToRgbaObject, RgbaDecimalObject } from './hex-rgba';

export const getBrightnessFromHex = (color: string) => {
  const colorRgba = hexToRgbaObject(color, 1);

  return getBrightness(colorRgba);
};

// Calc brightness: https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors
export const getBrightness = (rgbaObj: RgbaDecimalObject) =>
  0.2126 * rgbaObj.r + 0.7152 * rgbaObj.g + 0.0722 * rgbaObj.b;
